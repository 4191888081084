import React from 'react';
import { HomeIcon, UserIcon, FolderIcon, PhoneIcon, KeyIcon } from '@heroicons/react/24/outline';

const MobileMenu = () => {
    return (
        <nav className="fixed bottom-0 left-0 w-full bg-gray-200 dark:bg-gray-800 shadow-md md:hidden rounded-t-2xl">
            <ul className="flex justify-between items-center px-6 py-3 relative">
                {/* Home */}
                <li className="group">
                    <a href="/" className="flex flex-col items-center" aria-label="Go to Home">
                        <HomeIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                            alt="Home Icon"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">Home</span>
                    </a>
                </li>

                {/* About */}
                <li className="group">
                    <a href="/about" className="flex flex-col items-center" aria-label="Go to About">
                        <UserIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                            alt="About Icon"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">About</span>
                    </a>
                </li>

                {/* เพิ่มพื้นที่ว่าง */}
                <div className="w-6"></div>

                {/* Icon ตรงกลาง */}
                <li className="absolute -top-8 left-1/2 transform -translate-x-1/2 flex flex-col items-center">
                    <div
                        className="bg-yellow-400 w-14 h-14 rounded-full flex items-center justify-center shadow-lg group hover:scale-110 transition-transform">
                        <a href="/projects" aria-label="Go to Projects" className="flex items-center justify-center">
                            <FolderIcon
                                className="h-7 w-7 text-white group-hover:scale-125 transition-transform transform"
                                alt="Projects Icon"
                            />
                        </a>
                    </div>
                    <span className="text-sm text-gray-600 dark:text-gray-200 mt-2">Projects</span>
                </li>

                {/* Contact */}
                <li className="group">
                    <a href="/contact" className="flex flex-col items-center" aria-label="Go to Contact">
                        <PhoneIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                            alt="Contact Icon"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">Contact</span>
                    </a>
                </li>

                {/* Me */}
                <li className="group">
                    <a href="/me" className="flex flex-col items-center" aria-label="Go to My Profile">
                        <KeyIcon
                            className="h-6 w-6 text-gray-600 dark:text-gray-200 group-hover:text-yellow-400 transition-transform transform group-hover:scale-125"
                            alt="Profile Icon"
                        />
                        <span className="text-sm text-gray-600 dark:text-gray-200 group-hover:text-yellow-400">Me</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default MobileMenu;