import React, { useEffect, useState } from "react";
import {
    PhoneIcon,
    EnvelopeIcon,
    MapPinIcon,
} from "@heroicons/react/24/outline";
import { FaFacebookF } from "react-icons/fa"; // สำหรับ Facebook Icon
import { SiLine } from "react-icons/si";
import { Fade } from 'react-awesome-reveal';

const Home = () => {
    const words = [
        "HTML", "CSS", "JavaScript", "React", "PHP", "Python",
        "Machine Learning", "IoT", "Website", "Application",
        "Mobile App",
    ];
    const contactItems = [
        {
            type: "Phone",
            icon: <PhoneIcon className="w-6 h-6 text-white" />,
            value: "+1 234 567 890",
            bgColor: "bg-blue-500",
        },
        {
            type: "Email",
            icon: <EnvelopeIcon className="w-6 h-6 text-white" />,
            value: "example@email.com",
            bgColor: "bg-green-500",
        },
        {
            type: "Line ID",
            icon: <SiLine className="w-6 h-6 text-white" />,
            value: "@mylineid",
            bgColor: "bg-teal-500",
        },
        {
            type: "Facebook",
            icon: <FaFacebookF className="w-6 h-6 text-white" />,
            value: "facebook.com/myprofile",
            bgColor: "bg-purple-500",
        },
        {
            type: "Address",
            icon: <MapPinIcon className="w-6 h-6 text-white" />,
            value: "1234 Main St, Anytown, USA",
            bgColor: "bg-red-500",
        },
    ];

    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 1500); // เปลี่ยนคำทุก 1.5 วินาที
        return () => clearInterval(interval);
    }, []);

    const handleScroll = (sectionId) => {
        document.getElementById(sectionId).scrollIntoView({
            behavior: "smooth",
        });
    };

    return (
        <div className="scroll-snap-y snap-mandatory h-screen overflow-y-scroll">
            {/* Home Section */}
            <section
                id="home"
                className="snap-start flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition duration-300"
            >
                <div className="text-center p-6 max-w-4xl">
                    {/* Parallax Logo */}
                    <div
                        className="mb-8 flex justify-center"
                        style={{ perspective: "1000px" }}
                    >
                        <Fade direction="right">
                            <div
                                className="w-64 h-64 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 dark:from-yellow-500 dark:to-red-500 shadow-lg flex items-center justify-center animate-spin-slow"
                                style={{transformStyle: "preserve-3d"}}
                            >
                                <img
                                    src="/MyLogo.png"
                                    alt="De'trin Logo"
                                    className="w-60 h60 rounded-full animate-fade-in"
                                />
                            </div>
                        </Fade>

                </div>

                {/* Heading */}
                    <Fade direction="left">
                        <h1 className="text-4xl md:text-6xl font-extrabold mb-6">
                            สวัสดี I'm{" "}
                            <span className="text-blue-500 dark:text-yellow-400">
                            De'trin
                        </span>
                        </h1>
                    </Fade>


                    {/* Animated Words */}
                    <Fade direction="up" cascade damping={0.1}>
                    <p className="text-lg md:text-2xl mb-8">
                        I'm passionate about{" "}
                        <span className="font-semibold text-blue-500 dark:text-yellow-400">
                            {words[currentWordIndex]}
                        </span>
                    </p>

                    <p className="text-md md:text-lg mb-8">
                        Crafting innovative solutions for a better digital world.
                    </p>
                    </Fade>

                </div>
            </section>

            {/* About Section */}
            <section
                id="about"
                className="snap-start flex flex-col items-center justify-center h-screen bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-100 transition duration-300"
            >
                <div className="max-w-5xl mx-auto">
                    {/* หัวข้อ Section */}
                    <Fade direction="up" cascade damping={0.1}>
                        <h3 className="text-3xl font-extrabold text-gray-800 dark:text-gray-100 text-center mb-6">
                            About Me
                        </h3>
                        <p className="text-lg text-gray-600 dark:text-gray-300 text-center mb-12">
                            Discover my journey, expertise, and passion for creating innovative and impactful digital
                            solutions.
                        </p>
                    </Fade>

                    {/* Content */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                        {/* รูปภาพ */}
                        <Fade direction="left">
                            <div
                                className="relative w-full h-80 md:h-96 bg-cover bg-center rounded-lg shadow-lg overflow-hidden transform transition-transform duration-500 hover:scale-105"
                                style={{
                                    backgroundImage:
                                        'url("https://images.unsplash.com/photo-1593642532973-d31b6557fa68?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080")',
                                }}
                            >
                                <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                            </div>
                        </Fade>

                        {/* ข้อมูลส่วนตัว */}
                        <Fade direction="right">
                            <div className="text-center md:text-left">
                                <h4 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-4">
                                    Hi, I'm De'Trin
                                </h4>
                                <p className="text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                                    As a passionate software engineer, I specialize in building high-quality web
                                    applications that solve real-world problems. I enjoy collaborating with diverse
                                    teams to
                                    create intuitive, user-centered designs that drive impact and innovation.
                                </p>
                                <a
                                    href="/contact"
                                    className="inline-block px-6 py-3 bg-blue-500 text-white font-medium rounded-lg shadow-md hover:bg-blue-600 transform hover:-translate-y-1 transition duration-300"
                                >
                                    Contact Me
                                </a>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section
                id="contact"
                className="snap-start flex flex-col items-center justify-center h-screen bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-100 transition duration-300"
            >
                <Fade direction="right">
                    <h2 className="text-4xl font-bold mb-4">Contact Me</h2>
                    <p className="text-lg mb-8">
                        Let's connect and build something amazing together.
                    </p>
                </Fade>
                <Fade direction="left">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                        {contactItems.map((item, index) => (
                            <div key={index} className="flex items-center space-x-4">
                                <div className={`${item.bgColor} p-4 rounded-full shadow-md`}>
                                    {item.icon}
                                </div>
                                <div>
                                    <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                                        {item.type}
                                    </h4>
                                    <p className="text-gray-600 dark:text-gray-300">{item.value}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </Fade>
            </section>
            ;
        </div>
    );
};

export default Home;