import React, {useEffect, useState} from 'react';
import About from './pages/About';
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Me from "./pages/Me";
import Callback from "./pages/Callback";
import Navbar from './components/Navbar';
import ProjectDetail from "./pages/ProjectDetail";
import MobileMenu from './components/MobileMenu';
import Home from './components/Home';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";


function App() {
    const [darkMode, setDarkMode] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));

    useEffect(() => {
        if (localStorage.getItem("darkMode") === "true") {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    return (
        <div className={darkMode ? "dark" : ""}>
            <div className="min-h-screen bg-gray-100 dark:bg-gray-900 dark:text-gray-100 transition duration-300">
                <BrowserRouter>
                    <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
                    <Routes>
                        <Route path="/" element={<Navigate to="/home" />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route
                            path="/me"
                            element={
                                <ProtectedRoute isAuthenticated={isAuthenticated}>
                                    <Me />
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="/callback" element={<Callback setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="/logout" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="*" element={<Navigate to="/home" />} />
                        <Route path="/project/:id" element={<ProjectDetail projects={Projects} />} />
                    </Routes>
                    <MobileMenu darkMode={darkMode} setDarkMode={setDarkMode} />
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;
