import React from "react";

export default function Contact() {
    return (
        <section
            id="contact"
            className="py-16 px-6 bg-gray-100 dark:bg-gray-900 transition duration-300"
        >
            <div className="max-w-5xl mx-auto">
                {/* Section Heading */}
                <h3 className="text-3xl font-extrabold text-gray-800 dark:text-gray-100 text-center mb-6">
                    Contact Me
                </h3>
                <p className="text-lg text-gray-600 dark:text-gray-300 text-center mb-12">
                    Let's connect! Feel free to reach out via any of the channels below.
                </p>

                {/* Contact Info Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                    {/* Phone */}
                    <div className="flex items-center space-x-4">
                        <div className="p-4 bg-blue-500 text-white rounded-full shadow-md">
                            <i className="fas fa-phone-alt text-xl"></i>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                                Phone
                            </h4>
                            <p className="text-gray-600 dark:text-gray-300">
                                +1 234 567 890
                            </p>
                        </div>
                    </div>

                    {/* Email */}
                    <div className="flex items-center space-x-4">
                        <div className="p-4 bg-green-500 text-white rounded-full shadow-md">
                            <i className="fas fa-envelope text-xl"></i>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                                Email
                            </h4>
                            <p className="text-gray-600 dark:text-gray-300">
                                example@email.com
                            </p>
                        </div>
                    </div>

                    {/* Line */}
                    <div className="flex items-center space-x-4">
                        <div className="p-4 bg-teal-500 text-white rounded-full shadow-md">
                            <i className="fab fa-line text-xl"></i>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                                Line ID
                            </h4>
                            <p className="text-gray-600 dark:text-gray-300">
                                @mylineid
                            </p>
                        </div>
                    </div>

                    {/* Social Media */}
                    <div className="flex items-center space-x-4">
                        <div className="p-4 bg-purple-500 text-white rounded-full shadow-md">
                            <i className="fab fa-facebook-f text-xl"></i>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                                Facebook
                            </h4>
                            <p className="text-gray-600 dark:text-gray-300">
                                facebook.com/myprofile
                            </p>
                        </div>
                    </div>

                    {/* Address */}
                    <div className="flex items-center space-x-4">
                        <div className="p-4 bg-red-500 text-white rounded-full shadow-md">
                            <i className="fas fa-map-marker-alt text-xl"></i>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-100">
                                Address
                            </h4>
                            <p className="text-gray-600 dark:text-gray-300">
                                1234 Main St, Anytown, USA
                            </p>
                        </div>
                    </div>
                </div>

                {/* Google Maps */}
                <div className="rounded-lg overflow-hidden shadow-lg">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509327!2d144.95373531564536!3d-37.816279442021634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d2b8c9b05e65!2sEnvato!5e0!3m2!1sen!2sau!4v1649453140031!5m2!1sen!2sau"
                        width="100%"
                        height="400"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Maps"
                    ></iframe>
                </div>
            </div>
        </section>
    );
}