import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { ProjectContext } from "../context/ProjectContext";

export default function ProjectDetail() {
    const { id } = useParams();
    const { projects, loading, error } = useContext(ProjectContext);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <p className="text-xl text-gray-600 dark:text-gray-300">Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <p className="text-xl text-red-600 dark:text-red-400">{error}</p>
            </div>
        );
    }

    const project = projects.find((p) => p.id === parseInt(id));

    if (!project) {
        return (
            <div className="text-center mt-10">
                <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-100">
                    Project Not Found
                </h2>
                <Link
                    to="/projects"
                    className="mt-4 inline-block px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition"
                >
                    Back to Projects
                </Link>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-6 py-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                {/* Image Section */}
                <div className="relative">
                    <img
                        src={project.image}
                        alt={project.title}
                        className="rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
                    />
                </div>
                {/* Details Section */}
                <div className="flex flex-col items-start">
                    <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-100">
                        {project.title}
                    </h1>
                    <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
                        {project.description}
                    </p>
                    <div className="flex gap-4 mt-6">
                        <Link
                            to="/projects"
                            className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-100 font-medium rounded-md shadow hover:bg-gray-300 dark:hover:bg-gray-600 transition"
                        >
                            Back to Projects
                        </Link>
                        <a
                            href={project.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="px-4 py-2 bg-blue-500 text-white font-medium rounded-md shadow hover:bg-blue-600 transition"
                        >
                            Visit Project
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}