import Logout from "../components/Logout";
import axios from "axios";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import {
    Modal,
    Box,
    TextField,
    Button,
    MenuItem,
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
} from "@mui/material";

export default function Me() {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [projects, setProjects] = useState([]);
    const [projectTypes, setProjectTypes] = useState([]);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const [newProject, setNewProject] = useState({
        title: "",
        description: "",
        image: "",
        type_id: "",
        link: "",
    });

    // Fetch user data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/user`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                setData(response.data);
            } catch (err) {
                console.error("Failed to fetch user data:", err);
                setError("Failed to fetch user data.");
            }
        };

        if (isAuthenticated) fetchData();
    }, [isAuthenticated]);

    // Fetch projects and project types
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/project`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                setProjects(response.data.projects);
            } catch (err) {
                console.error("Failed to fetch projects:", err);
                setError("Failed to fetch projects.");
            }
        };

        const fetchProjectTypes = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/project`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                setProjectTypes(response.data.types);
            } catch (err) {
                console.error("Failed to fetch project types:", err);
                setError("Failed to fetch project types.");
            }
        };

        fetchProjects();
        fetchProjectTypes();
    }, []);

    // Handle modal open/close
    const handleOpen = (project = null) => {
        if (project) {
            setEditMode(true);
            setSelectedProject(project);
            setNewProject(project);
        } else {
            setEditMode(false);
            setNewProject({
                title: "",
                description: "",
                image: "",
                type_id: "",
                link: "",
            });
        }
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewProject((prev) => ({ ...prev, [name]: value }));
    };

    // Handle form submission
    const handleSaveProject = async () => {
        try {
            if (editMode) {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/project/update/${selectedProject.id}`,
                    newProject,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                Swal.fire({
                    icon: "success",
                    title: "Project Updated !",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setProjects((prev) =>
                    prev.map((project) =>
                        project.id === selectedProject.id ? newProject : project
                    )
                );
            } else {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}/project/add`,
                    newProject,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                Swal.fire({
                    icon: "success",
                    title: "Project added !",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setProjects((prev) =>
                    prev.map((project) =>
                        project.id === selectedProject.id ? newProject : project
                    )
                );
            }
            handleClose();
        } catch (err) {
            console.error("Failed to save project:", err);
            Swal.fire({
                icon: "error",
                title: "Failed to save project",
                text: "Please try again later.",
            });
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Welcome to your profile</h1>
            {error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div>
                    {data ? (
                        <div className="flex items-center space-x-4">
                            <img
                                src={`https://staycool.devtrin.com/${data.avatar}`}
                                alt={`${data.name}'s avatar`}
                                className="w-16 h-16 rounded-full"
                            />
                            <div>
                                <h2 className="text-xl font-semibold">{data.name}</h2>
                                <p className="text-gray-500">{data.email}</p>
                            </div>
                            <div className="flex items-center space-x-4">
                                <Logout setIsAuthenticated={setIsAuthenticated} />
                            </div>
                        </div>
                    ) : (
                        <p>Loading user data...</p>
                    )}
                </div>
            )}

            <div className="flex justify-between items-center my-4">
                <h4 className="text-2xl font-bold">My Projects</h4>
                <Button
                    variant="contained"
                    className="bg-blue-600 text-white hover:bg-blue-700 rounded-lg shadow"
                    onClick={() => handleOpen()}
                >
                    Add New Project
                </Button>
            </div>


            <Grid container spacing={4} className="my-8">
                {projects.map((project) => (
                    <Grid item xs={12} sm={6} md={4} key={project.id}>
                        <div className="bg-white rounded-lg shadow-md hover:shadow-lg p-6">
                            <h3 className="text-lg font-semibold text-gray-800 mb-2">
                                {project.title}
                            </h3>
                            <p className="text-sm text-gray-600 mb-4">
                                {project.description}
                            </p>
                            <img
                                src={project.image}
                                alt={project.title}
                                className="w-full h-40 object-cover rounded-md mb-4"
                            />
                            <div className="flex justify-between items-center">
                                <Button
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleOpen(project)}
                                >
                                    Edit
                                </Button>
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:underline text-sm"
                                >
                                    View
                                </a>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>

            {/* Modal for Adding/Editing Project */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    className="bg-white p-8 rounded-xl shadow-lg max-w-sm md:max-w-md lg:max-w-lg mx-auto mt-20"
                >
                    <h2 className="text-2xl font-bold mb-6">
                        {editMode ? "Edit Project" : "Add New Project"}
                    </h2>
                    <form className="space-y-4">
                        <TextField
                            fullWidth
                            label="Title"
                            name="title"
                            value={newProject.title}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            value={newProject.description}
                            onChange={handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4}
                        />
                        <TextField
                            fullWidth
                            label="Image URL"
                            name="image"
                            value={newProject.image}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            select
                            label="Type"
                            name="type_id"
                            value={newProject.type_id}
                            onChange={handleInputChange}
                            variant="outlined"
                        >
                            {projectTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            fullWidth
                            label="Link"
                            name="link"
                            value={newProject.link}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                        <div className="flex justify-end space-x-4 mt-6">
                            <Button variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveProject}
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}