import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import {ThemeContext} from "../context/ThemeContext";

const Navbar = () => {
    // ดึงค่า darkMode และ setDarkMode จาก ThemeContext
    const { darkMode, toggleTheme } = useContext(ThemeContext);

    return (
        <nav className="flex justify-between items-center px-4 py-2 bg-gray-200 dark:bg-gray-800 shadow-md">
            <Link to="/" className="text-lg font-bold" aria-label="Home page">De'Trin</Link>
            <ul className="hidden md:flex gap-4">
                <Link to="/about" aria-label="Go to About">About</Link>
                <Link to="/projects" aria-label="Go to Project">Projects</Link>
                <Link to="/contact" aria-label="Go to Contact">Contact</Link>
                <Link to="/me" aria-label="Go to Me"> Me </Link>
            </ul>
            <button onClick={toggleTheme} className="p-2 rounded-full" aria-label="Theme">
                {darkMode ? <SunIcon className="h-6 w-6 text-yellow-400" /> : <MoonIcon className="h-6 w-6 text-gray-800" />}
            </button>
        </nav>
    );
};

export default Navbar;