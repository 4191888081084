import React from 'react';
import {Fade} from "react-awesome-reveal";

export default function About() {
    return (
        <section
            id="about"
            className="py-16 px-6 bg-gray-100 dark:bg-gray-900 transition duration-300"
        >
            <Fade direction="up" cascade damping={0.1}>
                <h3 className="text-3xl font-extrabold text-gray-800 dark:text-gray-100 text-center mb-6">
                    About Me
                </h3>
                <p className="text-lg text-gray-600 dark:text-gray-300 text-center mb-12">
                    Discover my journey, expertise, and passion for creating innovative and impactful digital
                    solutions.
                </p>
            </Fade>

            {/* Content */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                {/* รูปภาพ */}
                <Fade direction="left">
                    <div
                        className="relative w-full h-80 md:h-96 bg-cover bg-center rounded-lg shadow-lg overflow-hidden transform transition-transform duration-500 hover:scale-105"
                        style={{
                            backgroundImage:
                                'url("https://images.unsplash.com/photo-1593642532973-d31b6557fa68?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=1080")',
                        }}
                    >
                        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                    </div>
                </Fade>

                {/* ข้อมูลส่วนตัว */}
                <Fade direction="right">
                    <div className="text-center md:text-left">
                        <h4 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-4">
                            Hi, I'm De'Trin
                        </h4>
                        <p className="text-gray-600 dark:text-gray-300 mb-6 leading-relaxed">
                            As a passionate software engineer, I specialize in building high-quality web
                            applications that solve real-world problems. I enjoy collaborating with diverse
                            teams to
                            create intuitive, user-centered designs that drive impact and innovation.
                        </p>
                        <a
                            href="/contact"
                            className="inline-block px-6 py-3 bg-blue-500 text-white font-medium rounded-lg shadow-md hover:bg-blue-600 transform hover:-translate-y-1 transition duration-300"
                        >
                            Get in Touch
                        </a>
                    </div>
                </Fade>
            </div>
        </section>
    );
}