import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {ProjectContext} from "../context/ProjectContext";

export default function Projects() {

    const { projects, loading, error } = useContext(ProjectContext);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <p className="text-xl text-gray-600 dark:text-gray-300">Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <p className="text-xl text-red-600 dark:text-red-400">{error}</p>
            </div>
        );
    }

    return (
        <section
            id="project"
            className="py-16 px-6 bg-gray-100 dark:bg-gray-900 transition duration-300"
        >
            <div className="max-w-6xl mx-auto">
                <h3 className="text-4xl font-extrabold text-gray-800 dark:text-gray-100 text-center mb-8">
                    My Projects
                </h3>

                <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {projects.map((project) => (
                        <Link
                            to={`/project/${project.id}`}
                            key={project.id}
                            className="block bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105"
                        >
                            <img
                                src={project.image}
                                alt={project.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-6">
                                <h4 className="text-xl font-bold text-gray-800 dark:text-gray-100 mb-2">
                                    {project.title}
                                </h4>
                                <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-2">
                                    {project.description}
                                </p>
                                <span className="text-blue-500 hover:text-blue-700 font-medium">
                                    View Details →
                                </span>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
}