import React, { createContext, useState, useEffect } from 'react';
import axios from "axios";

// สร้าง Context
export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('https://staycool.devtrin.com/api/projects');
                setProjects(response.data.projects);
            } catch (err) {
                setError('Failed to fetch projects');
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    return (
        <ProjectContext.Provider value={{ projects, loading, error }}>
            {children}
        </ProjectContext.Provider>
    );
}
