import React, {useEffect, useState} from "react";
import { Link,useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login({setIsAuthenticated}) {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // ใช้สำหรับ Redirect
    // ฟังก์ชันจัดการการ Login

    const login = async (e) => {
        e.preventDefault();
        const email = e.target[0].value;
        const password = e.target[1].value;
        try {
            const { data } = await axios.post(
                `https://staycool.devtrin.com/api/login`, // URL Laravel
                { email, password } // ส่งข้อมูล email และ password ไปยัง API
            );
            setUserData(data); // เก็บข้อมูลผู้ใช้ที่ได้รับ
            localStorage.setItem("token", data.access_token); // เก็บ token ใน Local Storage
            // อัปเดตสถานะการ Login
            setIsAuthenticated(true);

            navigate("/me");// ส่งผู้ใช้ไปหน้า /me
        } catch (err) {
            console.error("Error during login:", err);
            setError("Login failed. Please try again.");
        }
    }

    useEffect(() => {
        if (userData) {
            navigate("/me");
        }

    }, [userData]);

    return (

        <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900">
            <div className="w-full max-w-md bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-100 text-center mb-6">
                    Hello Trin
                </h2>
                <p className="text-gray-600 dark:text-gray-400 text-center mb-8">
                    Please login to your account
                </p>
                <form onSubmit={login}
                        onKeyDown={(e) => { if (e.key === 'Enter') console.log("Enter Pressed") }}
                >
                    {/* Email Input */}
                    <div className="mb-4">
                        <label
                            htmlFor="email"
                            className="block text-gray-700 dark:text-gray-300 font-medium mb-2"
                        >
                            Email Address
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-gray-800 dark:text-gray-100 bg-gray-50 dark:bg-gray-900 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                            placeholder="Enter your email"
                        />
                    </div>

                    {/* Password Input */}
                    <div className="mb-6">
                        <label
                            htmlFor="password"
                            className="block text-gray-700 dark:text-gray-300 font-medium mb-2"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="w-full px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-gray-800 dark:text-gray-100 bg-gray-50 dark:bg-gray-900 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                            placeholder="Enter your password"
                        />
                    </div>

                    {/* Login Button */}
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-blue-500 text-white rounded-md shadow-md font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    >
                        Login
                    </button>
                </form>

                {/* ข้อผิดพลาด */}
                {error && <p className="text-red-500 mt-4">{error}</p>}

            </div>
        </div>
    );
}